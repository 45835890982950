<template>
    <div class="collection-item">
        <div class="collection-item__img-box">
            <img class="collection-item__img" :src="item.img" :id="item.id" alt="img-collection-items">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: Object,
    },
}
</script>