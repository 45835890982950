<template>
    <div class="box-content">
        <HeaderComponent />
        <div class="content-news center">
            <div class="content-news__news-item">
                <h2 class="content-news__news-item_title" data-lang="news-1"></h2>
                <p class="content-news__news-item_text" data-lang="news-2"></p>
                <div class="content-news__news-item_box-video">
                    <video class="content-news__news-item_video" src="@/assets/video/spring.mp4" controls></video>
                </div>
            </div>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import { useHead } from '@unhead/vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

import { mapMutations } from 'vuex';

export default {
    name: 'news',
    components: { HeaderComponent, FooterComponent },
    setup() {
        useHead({
            title:
                'Новости САЛОН&СТУДИЯ SHALIONI CREATIVE',
            meta: [
                {
                    name: 'news',
                    content: 'Новости САЛОН&СТУДИЯ SHALIONI CREATIVE',
                }
            ],
        });
    },
    methods: {
        ...mapMutations(['checkPagePatName', 'changeLang']),
    },
    mounted() {
        this.checkPagePatName();
        this.changeLang();
    },
}
</script>