import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { createHead } from '@unhead/vue'

import './assets/fonts/stylesheet.css'
import './assets/styles/style.css'

createApp(App).use(createHead()).use(store).use(router).mount('#app')


