<template>
    <div v-if="pageCount > 1" class="pagination">
        <router-link :to="getPageLink(currentPage)">
            <button :disabled="currentPage === 1" @click="prevPage" class="pagination__button">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                    height="20" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 512 512"
                    xml:space="preserve" class="">
                    <g>
                        <path
                            d="M84 108a3.988 3.988 0 0 1-2.828-1.172l-40-40a3.997 3.997 0 0 1 0-5.656l40-40c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656L49.656 64l37.172 37.172a3.997 3.997 0 0 1 0 5.656A3.988 3.988 0 0 1 84 108z"
                            fill="$colorText" opacity="1" data-original="#000000" class=""></path>
                    </g>
                </svg>
            </button>
        </router-link>
        <router-link v-for="pageNumber in pageCount" :key="pageNumber" :to="getPageLink(pageNumber)"         @click="changeCurrentPage(pageNumber)">
            <div class="pagination__button">{{ pageNumber }}</div>
        </router-link>
        <router-link :to="getPageLink(currentPage)">
            <button :disabled="currentPage === pageCount" @click="nextPage" class="pagination__button">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                    height="20" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 512 512"
                    xml:space="preserve" class="">
                    <g>
                        <path
                            d="M44 108a3.988 3.988 0 0 1-2.828-1.172 3.997 3.997 0 0 1 0-5.656L78.344 64 41.172 26.828c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40a3.997 3.997 0 0 1 0 5.656l-40 40A3.988 3.988 0 0 1 44 108z"
                            fill="$colorText" opacity="1" data-original="#000000" class=""></path>
                    </g>
                </svg>
            </button>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'PaginatedPageComponent',
    props: {
        pageCount: Number,
        path: String,
    },

    data() {
        return {
            currentPage: 1,
        }
    },
    methods: {
        changeCurrentPage(pageNumber) {
            return this.currentPage = pageNumber;
        },
        getPageLink(pageNumber) {
            return `/${this.path}/${pageNumber}`;
        },
        nextPage() {
            return this.currentPage += 1;
        },
        prevPage() {
            return this.currentPage -= 1;
        },
    },
}
</script>