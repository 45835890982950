<template>
    <div class="box-content">
        <div class="box-click" @click="sliderClickCollections">
            <HeaderComponent />
            <div class="content-collections center" :class="classOpacity">
                <CollectionsItemComponent v-for="item in collections" :key="item.id" :item="item" :id="item.id" />
            </div>
            <div class="modalForSlider center" id="modal">
                <SliderCollectionComponent :collection="this.currentCol" :key="componentKeySlider" />
            </div>
            <FooterComponent />
        </div>
    </div>
</template>

<script>
import { useHead } from '@unhead/vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import CollectionsItemComponent from '@/components/CollectionsItemComponent.vue';
import SliderCollectionComponent from '@/components/SliderCollectionComponent.vue';

import { mapState, mapMutations } from 'vuex';

export default {
    name: 'collections',
    components: { HeaderComponent, FooterComponent, CollectionsItemComponent, SliderCollectionComponent },
    setup() {
        useHead({
            title:
                'Коллекции мужской и женской коллекций САЛОН&СТУДИЯ SHALIONI CREATIVE',
            meta: [
                {
                    name: 'collections',
                    content: 'Коллекции мужской и женской коллекций САЛОН&СТУДИЯ SHALIONI CREATIVE',
                }
            ],
        });
    },
    data() {
        return {
            collections: [
                {
                    id: 1,
                    img: require('@/assets/img/collections/spring/1.jpg'),
                    dataLang: 'collections-1',
                },
                {
                    id: 2,
                    img: require('@/assets/img/collections/biopolarity/1.jpg'),
                    dataLang: 'collections-2',
                },
                {
                    id: 3,
                    img: require('@/assets/img/collections/game/1.jpg'),
                    dataLang: 'collections-3',
                },
                {
                    id: 4,
                    img: require('@/assets/img/collections/sketch/1.jpg'),
                    dataLang: 'collections-4',
                },
                {
                    id: 5,
                    img: require('@/assets/img/collections/venice/1.jpg'),
                    dataLang: 'collections-5',
                },
                {
                    id: 6,
                    img: require('@/assets/img/collections/afterpatya/1.jpg'),
                    dataLang: 'collections-6',
                },
                {
                    id: 7,
                    img: require('@/assets/img/collections/dream/1.jpg'),
                    dataLang: 'collections-7',
                },
            ],
            modal: '',
            currentCol: [],
            idImg: 1,
            componentKeySlider: 0,
        }
    },
    methods: {
        ...mapMutations(['checkPagePatName', 'changeLang', 'addClassOpacity', 'dellClassOpacity']),

        forceRenderSlider() {
            this.componentKeySlider += 1;
        },
        sliderClickCollections(e) {
            const links = e.target.closest('a');
            this.modal = document.getElementById("modal");
            if (!links) {
                const isModal = e.composedPath().includes(this.modal);
                if (e.target.tagName === 'IMG' && !isModal) {
                    this.idImg = e.target.id;
                    const start = this.collections.slice(this.idImg - 1, this.collections.length + 1);
                    const end = this.collections.slice(0, this.idImg - 1);
                    this.currentCol = start.concat(end);
                    this.modal.style.display = "block";
                    this.modal.scrollIntoView({ block: "start", behavior: "smooth" });
                    this.addClassOpacity();
                    this.forceRenderSlider();
                } else {
                    if (!isModal && this.modal.style.display === "block") {
                        this.modal.style.display = "none";
                        this.dellClassOpacity();
                    }
                }
            }
            else {
                this.dellClassOpacity();
            }
        }
    },
    mounted() {
        this.checkPagePatName();
        this.changeLang();
    },
    computed: {
        ...mapState(['classOpacity'])
    }
}
</script>