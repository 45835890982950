<template>
  <swiper :style="{
    '--swiper-navigation-color': 'white',
  }" :zoom="true" :navigation="true" @swiper="onSwiper" :modules="modules" class="mySwiper">
    <swiper-slide v-for="item, index in collection" :key="item.id">
      <div class="swiper-zoom-container">
        <img :src="collection[index].img" :id="collection[index].id" />
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import 'swiper/css/zoom';
import 'swiper/css/navigation';
import { Zoom, Navigation } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Zoom, Navigation],
    };
  },
  props: {
    collection: Array,
  },
};
</script>

