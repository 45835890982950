<template>
    <div class="slider">
        <div class="slider__items">
            <article class="slider__item slider__item-current" data-slide="0"><img class="img-slider"
                    src="@/assets/img/slider_index/slide_1.jpg" alt=""></article>
            <article class="slider__item" data-slide="1"><img class="img-slider" src="@/assets/img/slider_index/slide_2.jpg"
                    alt=""></article>
            <article class="slider__item" data-slide="2"><img class="img-slider" src="@/assets/img/slider_index/slide_3.jpg"
                    alt=""></article>
            <article class="slider__item" data-slide="3"><img class="img-slider" src="@/assets/img/slider_index/slide_4.jpg"
                    alt=""></article>
        </div>
    </div>
    <div class="btn-box">
        <button @click="clickBtn" class="btn-box__btnSlider" id="Previous"></button>
        <button @click="clickBtn" class="btn-box__btnSlider" id="Next"></button>
    </div>
    <div class="indicators-box">
        <div class="indicators-box__indicators">
            <button @click="clickInd" class="indicators-box__indicators_indicator" id="0"></button>
            <button @click="clickInd" class="indicators-box__indicators_indicator" id="1"></button>
            <button @click="clickInd" class="indicators-box__indicators_indicator" id="2"></button>
            <button @click="clickInd" class="indicators-box__indicators_indicator" id="3"></button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SliderComponent',
    data() {
        return {
            currentIndex: 0,
        }
    },
    methods: {
        clickBtn(e) {
            const amountSlides = document.querySelectorAll(".slider__item").length;

            if (e.target.id === "Next") {
                this.currentIndex = this.currentIndex + 1;
                if (this.currentIndex <= amountSlides) {
                    this.slideElementByDataAttribute("data-slide", this.currentIndex, "Next");
                } else {
                    this.currentIndex = 1;
                    this.slideElementByDataAttribute("data-slide", this.currentIndex, "Next");
                }
            } else {
                this.currentIndex = this.currentIndex - 1;
                if (this.currentIndex > -1) {
                    this.slideElementByDataAttribute("data-slide", this.currentIndex, "Previous");
                } else {
                    this.currentIndex = amountSlides - 1;
                    this.slideElementByDataAttribute("data-slide", this.currentIndex, "Previous");
                }
            }

        },

        slideElementByDataAttribute(attributeName, currentIndex, btnText) {
            if (btnText === "Next") {
                const elements = Array.from(document.querySelectorAll(`[${attributeName}]`));
                elements.forEach((element, index) => {
                    const slideCurrent = parseFloat(element.getAttribute(attributeName));
                    if (this.currentIndex === slideCurrent && this.currentIndex <= elements.length) {
                        elements[index - 1].style.display = "none";
                        element.style.display = "block";
                    }
                    if (this.currentIndex === elements.length) {
                        elements[elements.length - 1].style.display = "none";
                        elements[0].style.display = "block";
                    }
                });
            } else {
                const elements = Array.from(
                    document.querySelectorAll(`[${attributeName}]`));
                elements.forEach((element, index) => {
                    const slideCurrent = parseFloat(element.getAttribute(attributeName));
                    if (currentIndex === slideCurrent && currentIndex < elements.length - 1) {
                        elements[index + 1].style.display = "none";
                        element.style.display = "block";
                    }
                    if (currentIndex === elements.length - 1) {
                        elements[0].style.display = "none";
                        elements[elements.length - 1].style.display = "block";
                    }
                });
            }
        },
        clickInd(e) {
            const idIndicator = Number(e.target.id);
            const elements = Array.from(document.querySelectorAll(`[${"data-slide"}]`));
            elements.forEach((element) => {
                const slideCurrent = parseFloat(element.getAttribute("data-slide"));
                if (idIndicator === slideCurrent) {
                    element.style.display = "block";
                    this.currentIndex = idIndicator;
                } else {
                    element.style.display = "none";
                }
            });
        }
    }
}
</script>
