<template>
    <footer class="footer center">
        <div class="footer__top-content center">
            <nav class="menu">
                <ul class="menu__list">
                    <MenuComponent v-for="link in menu" :key="link.id" :link="link" />
                </ul>
            </nav>
        </div>
        <div class="footer__botoom-content center">
            <div class="footer__copyright center">
                <p>&copy; 2024 SHALIONI creative</p>
            </div>
        </div>
        <div class="footer__info">
            <div class="footer__left-info">
                <div class="footer__logo-box">
                    <a href="/">
                        <img class="img-logo" src="@/assets/img/Logo.png" alt="img_logo">
                    </a>
                </div>
                <div class="footer__icons">
                    <a class="footer__icons_icon" href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="38" height="38" x="0" y="0" viewBox="0 0 504 504"
                            style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                            <g>
                                <path
                                    d="M377.6 0H126C56.8 0 0 56.8 0 126.4V378c0 69.2 56.8 126 126 126h251.6c69.6 0 126.4-56.8 126.4-126.4V126.4C504 56.8 447.2 0 377.6 0zm-58 252H272v156h-60V252h-32v-64h28v-27.2c0-25.6 12.8-66 66.8-66H324V148h-34.8c-5.6 0-13.2 3.6-13.2 16v24h49.2l-5.6 64z"
                                    fill="#ffffff" opacity="1" data-original="#000000" class=""></path>
                            </g>
                        </svg>
                    </a>
                    <a class="footer__icons_icon" href="https://vk.com/album11426232_125613845">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="38" height="38" x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512"
                            xml:space="preserve" class="">
                            <g>
                                <path
                                    d="M22.316 1.684C20.632 0 17.921 0 12.5 0h-1C6.079 0 3.368 0 1.684 1.684 0 3.368 0 6.079 0 11.5v1c0 5.421 0 8.131 1.684 9.816S6.079 24 11.5 24h1c5.421 0 8.131 0 9.816-1.684C24 20.632 24 17.921 24 12.5v-1c0-5.421 0-8.132-1.684-9.816zM19.503 17h-1.75c-.667 0-.863-.532-2.05-1.719-1.039-1.001-1.484-1.131-1.743-1.131-.353 0-.458.1-.458.6v1.569c0 .43-.137.681-1.25.681-1.854 0-3.892-1.126-5.339-3.202-2.17-3.041-2.763-5.34-2.763-5.803 0-.26.1-.495.6-.495h1.751c.447 0 .615.196.783.68.856 2.493 2.3 4.672 2.893 4.672.222 0 .324-.103.324-.667V9.608c-.065-1.186-.696-1.284-.696-1.706 0-.195.167-.402.445-.402h2.751c.371 0 .5.198.5.643v3.467c0 .37.161.5.272.5.223 0 .408-.13.816-.538 1.261-1.409 2.151-3.578 2.151-3.578.112-.26.316-.495.762-.495h1.75c.529 0 .641.272.529.643-.223 1.02-2.355 4.023-2.355 4.023-.186.297-.26.445 0 .779.186.26.797.779 1.205 1.261.752.846 1.319 1.559 1.477 2.051.146.494-.105.744-.605.744z"
                                    fill="#ffffff" opacity="1" data-original="#000000" class=""></path>
                            </g>
                        </svg>
                    </a>
                    <a class="footer__icons_icon" href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="38" height="38" x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512"
                            xml:space="preserve" class="">
                            <g>
                                <path
                                    d="M12 2.162c3.204 0 3.584.012 4.849.07 1.308.06 2.655.358 3.608 1.311.962.962 1.251 2.296 1.311 3.608.058 1.265.07 1.645.07 4.849s-.012 3.584-.07 4.849c-.059 1.301-.364 2.661-1.311 3.608-.962.962-2.295 1.251-3.608 1.311-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07c-1.291-.059-2.669-.371-3.608-1.311-.957-.957-1.251-2.304-1.311-3.608-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849c.059-1.296.367-2.664 1.311-3.608.96-.96 2.299-1.251 3.608-1.311 1.265-.058 1.645-.07 4.849-.07M12 0C8.741 0 8.332.014 7.052.072 5.197.157 3.355.673 2.014 2.014.668 3.36.157 5.198.072 7.052.014 8.332 0 8.741 0 12c0 3.259.014 3.668.072 4.948.085 1.853.603 3.7 1.942 5.038 1.345 1.345 3.186 1.857 5.038 1.942C8.332 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 1.854-.085 3.698-.602 5.038-1.942 1.347-1.347 1.857-3.184 1.942-5.038.058-1.28.072-1.689.072-4.948 0-3.259-.014-3.668-.072-4.948-.085-1.855-.602-3.698-1.942-5.038C20.643.671 18.797.156 16.948.072 15.668.014 15.259 0 12 0z"
                                    fill="#ffffff" opacity="1" data-original="#000000" class=""></path>
                                <path
                                    d="M12 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                                    fill="#ffffff" opacity="1" data-original="#000000" class=""></path>
                                <circle cx="18.406" cy="5.594" r="1.44" fill="#ffffff" opacity="1" data-original="#000000"
                                    class=""></circle>
                            </g>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="footer__contacts">
                <p class="footer__contacts_contact" data-lang="footer-1"></p>
                <div class="footer__contacts_address">
                    <p class="footer__contacts_name" data-lang="footer-2"></p>
                    <p class="footer__contacts_contact" data-lang="footer-3"></p>
                </div>
                <div class="footer__contacts_email">
                    <p class="footer__contacts_name" data-lang="footer-4"></p>
                    <p class="footer__contacts_contact" data-lang="footer-5"></p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import MenuComponent from './MenuComponent.vue';
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'FooterComponent',
    components: { MenuComponent },
  
    data() {
        return {
            menu: [
                {
                    id: 1,
                    name: 'КОЛЛЕКЦИИ',
                    url: '/collections',
                    dataLink: 'Collections',
                    dataLang: "menu-footer-1",
                    children: false,
                },
                {
                    id: 2,
                    name: 'О НАС',
                    url: '/about',
                    dataLink: 'About',
                    dataLang: "menu-footer-2",
                    children: false,
                },
                {
                    id: 3,
                    name: 'КУПИТЬ',
                    url: '/buy',
                    dataLink: 'Buy',
                    dataLang: "menu-footer-3",
                    children: false,
                },
                {
                    id: 4,
                    name: 'НОВОСТИ',
                    url: 'news',
                    dataLink: 'News',
                    dataLang: "menu-footer-4",
                    children: false,
                },
                {
                    id: 5,
                    name: 'ШОУРУМ',
                    url: '/showroom',
                    dataLink: 'Showroom',
                    dataLang: "menu-footer-5",
                    children: false,
                },
                {
                    id: 6,
                    name: 'КОНТАКТЫ',
                    url: '/contacts',
                    dataLink: 'Contacts',
                    dataLang: "menu-footer-6",
                    children: false,
                },
            ],
        }
    },
    methods: {
        ...mapMutations(['currentLangMenu', 'currentLangFooter']),
    },
    mounted() {
        this.currentLangMenu();
        this.currentLangFooter();
    },
}
</script>
