<template>
    <div class="collections-item">
        <div class="collections-item__img-box">
            <img class="collections-item__img" :src="item.img" :id="item.id" alt="img-collection-items">
        </div>
        <h3 :data-lang="item.dataLang"></h3>
    </div>
</template>

<script>
export default {
    props: {
        item: Object,
    },
}
</script>