<template>
    <div class="box-content">
        <HeaderComponent />
        <div class="content-order center">
            <div class="content-order__img-content">
                <ProductComponent v-for="item in paginatedData" :key="item.id" :item="item" />
            </div>
            <div class="content-order__paginator">
                <PaginatedPageComponent :pageCount="pageCount" :path="path" />
            </div>
            <div class="content-order__form-content">
                <div class="content-order__form-info">
                    <h2 class="content-order__formx-info_title" data-lang="order-1"></h2>
                    <p class="content-order__form-info_text" data-lang="order-2"></p>
                    <p class="content-order__form-info_text" data-lang="order-3"></p>
                </div>
                <form class="content-order__form" id="formBuy">
                    <h3 class="content-order__form_title" data-lang="order-4"></h3>
                    <div class="content-order__form_name">
                        <input type="text" name="firstName" maxlength="12" required placeholder="Имя"
                            v-model.text="firstName">
                        <input type="text" name="lastName" maxlength="16" required placeholder="Фамилия"
                            v-model.text="lastName">
                    </div>
                    <div class="content-order__form_gender">
                        <input checked name="checkboxM" type="checkbox" @click="selectCheckbox" id="m"
                            class="content-order__form_gender_check">
                        <label for="m" data-lang="order-5"></label>
                        <input type="checkbox" name="checkboxF" @click="selectCheckbox" id="f"
                            class="content-order__form_gender_check"><label for="f" data-lang="order-6"></label>
                    </div>
                    <h3 class="content-order__form_title" data-lang="order-7"></h3>
                    <div class="content-order__form_email">
                        <input type="email" name="email" required placeholder="Email"
                            :class="{ 'notvalid': failedValidationEmail.email, 'valid': failedValidationEmail.email === false }"
                            v-model.email="email" @input="emailValidation" maxlength="30">
                        <input type="phone" name="phone" required placeholder="+7"
                            :class="{ 'notvalid': failedValidationPhone.phone, 'valid': failedValidationPhone.phone === false }"
                            v-model.phone="phone" @input="phoneValidation" maxlength="12">
                    </div>
                    <textarea id="form_message" name="message" cols="52" rows="10" maxlength="1000" required
                        placeholder="Текст сообщения" v-model.text="message"></textarea>
                    <button type="submit" @click="checkFormBtn" class="content-order__form_button"
                        data-lang="order-8"></button>
                </form>
                <div class="modalForm" id="modalForm" data-lang="order-9">
                    <h3></h3>
                </div>
            </div>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import { useHead } from '@unhead/vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import ProductComponent from '@/components/ProductComponent.vue';
import PaginatedPageComponent from '@/components/PaginatedPageComponent.vue';

import { mapState, mapMutations } from 'vuex';

export default {
    name: 'buy',
    components: { HeaderComponent, FooterComponent, ProductComponent, PaginatedPageComponent },
    setup() {
        useHead({
            title:
                'Купить | индивидуальный пошив | САЛОН&СТУДИЯ SHALIONI CREATIVE',
            meta: [
                {
                    name: 'buy',
                    content: 'Купить индивидуальный пошив мужской и женской одежды САЛОН&СТУДИЯ SHALIONI CREATIVE',
                }
            ],
        });
    },
    data() {
        return {
            products: [
                {
                    id: 1,
                    img: require('@/assets/img/products/1.jpg'),
                    titleRu: 'Блуза',
                    titleEn: 'Blouse',
                    descriptionRu: 'Хлопковая блуза с отстрочками. Рукав фанарик.',
                    descriptionEn: 'Cotton blouse with stitching. Flashlight sleeve.',
                    structureRu: '100% хлопок',
                    structureEn: '100% cotton',
                    sizeRu: '46-48',
                    sizeEn: '46-48',
                    priceRu: '20 000 РУБ',
                    priceEn: '20 000 RUB'
                },
                {
                    id: 2,
                    img: require('@/assets/img/products/2.jpg'),
                    titleRu: 'Жакет-фрак',
                    titleEn: 'Tailcoat jacket',
                    descriptionRu: 'Однобортный фрак выполнен из тонкой цветной шерсти. Широкий рукав. Подклад -однотонный шелк',
                    descriptionEn: 'The single-breasted tailcoat is made of fine colored wool. Wide sleeve. The lining is plain silk',
                    structureRu: '100% шерсть, 100% шелк',
                    structureEn: '100% wool, 100% silk',
                    sizeRu: '46-48',
                    sizeEn: '46-48',
                    priceRu: '20 000 РУБ',
                    priceEn: '20 000 RUB'
                },
                {
                    id: 3,
                    img: require('@/assets/img/products/3.jpg'),
                    titleRu: 'Жакет бархат',
                    titleEn: 'Jacket velvet',
                    descriptionRu: 'Жакет выполнен из мягкого бархата. Рукав кимоно. Застежка - кнопки',
                    descriptionEn: 'The jacket is made of soft velvet. Kimono sleeve. Clasp - buttons',
                    structureRu: '100% хлопок',
                    structureEn: '100% cotton',
                    sizeRu: '46-48',
                    sizeEn: '46-48',
                    priceRu: '20 000 РУБ',
                    priceEn: '20 000 RUB'
                },
                {
                    id: 4,
                    img: require('@/assets/img/products/4.jpg'),
                    titleRu: 'Бомбер 1',
                    titleEn: 'Bomber 1',
                    descriptionRu: 'Бомбер выполнен из кружева без подкладки. Застежка - молния',
                    descriptionEn: 'The bomber jacket is made of lace without lining. Zipper',
                    structureRu: '100% хлопок',
                    structureEn: '100% cotton',
                    sizeRu: '46-48',
                    sizeEn: '46-48',
                    priceRu: '20 000 РУБ',
                    priceEn: '20 000 RUB'
                },
                {
                    id: 5,
                    img: require('@/assets/img/products/5.jpg'),
                    titleRu: 'Бомбер 2',
                    titleEn: 'Bomber 2',
                    descriptionRu: 'Бомбер выполнен из тонкого кружева. Подклад из однотонного шелка. Застежка - молния',
                    descriptionEn: 'Bomber jacket made of thin lace. Lined in plain silk. Zipper',
                    structureRu: '100% хлопок, 100% шелк',
                    structureEn: '100% cotton, 100% silk',
                    sizeRu: '46-48',
                    sizeEn: '46-48',
                    priceRu: '20 000 РУБ',
                    priceEn: '20 000 RUB'
                },
                {
                    id: 6,
                    img: require('@/assets/img/products/6.jpg'),
                    titleRu: 'Платье с рукавом',
                    titleEn: 'Dress with sleeve',
                    descriptionRu: 'Платье выполнено из тонкой шерсти. Декор - репсовая лента. Застежка - кнопки',
                    descriptionEn: 'The dress is made of fine wool. Decor - grosgrain ribbon. Clasp - buttons',
                    structureRu: '100% шерсть',
                    structureEn: '100% wool',
                    sizeRu: '46-48',
                    sizeEn: '46-48',
                    priceRu: '20 000 РУБ',
                    priceEn: '20 000 RUB'
                },
                {
                    id: 7,
                    img: require('@/assets/img/products/7.jpg'),
                    titleRu: 'Платье без рукава',
                    titleEn: 'Sleeveless dress',
                    descriptionRu: 'Платье свободного кроя из плотного шелка',
                    descriptionEn: 'Loose-fitting dress made of thick silk',
                    structureRu: '100% шелк',
                    structureEn: '100% silk',
                    sizeRu: '46-48',
                    sizeEn: '46-48',
                    priceRu: '20 000 РУБ',
                    priceEn: '20 000 RUB'
                },
            ],

            sizePage: 3,
            path: 'Buy',

            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            message: null,
            errors: [],
            failedValidationEmail: {
                email: null
            },
            failedValidationPhone: {
                phone: null
            },
        }
    },

    methods: {
        ...mapMutations(['checkPagePatName', 'changeLang']),

        selectCheckbox(e) {
            const checkButtons = document.querySelectorAll('[type="checkbox"]');
            this.resetChecked(checkButtons);
            if (e) {
                e.target.checked = true;
            }
        },
        resetChecked(arr) {
            arr.forEach(check => {
                check.checked = false;
            });
        },

        getCurrentPageNumber() {
            const pageNumberParam = parseInt(this.$route.params.pageNumber);
            return isNaN(pageNumberParam) || pageNumberParam < 1 ? 1 : pageNumberParam;
        },

        emailValidation() {
            this.failedValidationEmail.email = !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(this.email) ? true : false;
        },
        phoneValidation() {
            this.failedValidationPhone.phone = !/^\+[\d]{11}$/.test(this.phone) ? true : false;
        },
        formValidation() {
            this.errors = [];
            if (!this.firstName)
                this.errors.push('firstName');
            if (!this.lastName)
                this.errors.push('lastName');
            if (this.failedValidationEmail.email || !this.email)
                this.errors.push('email');
            if (this.failedValidationPhone.phone || !this.phone)
                this.errors.push('phone');
            if (!this.errors.length)
                return true;
        },
        
        async checkFormBtn(e) {
            e.preventDefault();
            this.formValidation();
            if (!this.errors.length) {
                const data = new FormData(formBuy);
                const { status, error } = await this.sendData(data);
                if (status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' + status);
                }
                if (status === 200) {
                    this.modalVisible = document.getElementById('modalForm');
                    this.modalVisible.style.visibility = 'visible';
                    setTimeout(() => {
                        this.modalVisible.style.visibility = 'hidden';
                    }, 2000);
                    formBuy.reset();
                } if (error) {
                    this.onError(error);
                }
            }
            else {
                alert('Заполните все поля!');
            }
        },
        async sendData(data) {
            return await fetch('https://shalionicreative.ru/action.php', {
                method: 'POST',
                headers: { 'Content-Type': 'multipart/form-data' },
                body: data,
            })
        },
        onError(error) {
            alert(error.message);
        },
    },
    computed: {
        ...mapState(['currentLang']),

        pageCount() {
            return Math.ceil(this.products.length / this.sizePage);
        },
        paginatedData() {
            const pageNumber = this.getCurrentPageNumber();
            const start = (pageNumber - 1) * this.sizePage,
                end = start + this.sizePage;
            return this.products.slice(start, end);
        }
    },
    mounted() {
        this.checkPagePatName();
        this.changeLang();
    },
}
</script>

<style scoped lang="scss">
.notvalid {
    border: 1px solid red;
}

.valid {
    border: 1px solid #030303;
}

#modalForm {
    width: 250px;
    height: 48px;
    background-color: #FFFFFF;
    border: 1px solid #b0b0b0;
    color: #121212;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 11px;
    visibility: hidden;
    position: absolute;
    margin-top: 430px;
    margin-left: 660px;
}
</style>

