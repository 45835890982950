<template>
    <template v-if="link.children === true">
        <li>
            <div class="menu-collections">
                <router-link :to="link.url" :data-link="link.dataLink" :data-lang="link.dataLang"
                    @click="currentPathNameClick"> {{ link.name }}</router-link>

                <div class="menu-collections__content">
                    <ul>
                        <li v-if="this.currentLang === 'ru'">
                            <router-link :to="link.url" :data-link="link.dataLink" :id="link.id"
                                v-for="link in menuCollections" :key="link.id" @click="selectCollection"> {{ link.nameRu }}
                            </router-link>
                        </li>
                        <li v-if="this.currentLang === 'en'">
                            <router-link :to="link.url" :data-link="link.dataLink" :id="link.id"
                                v-for="link in menuCollections" :key="link.id" @click="selectCollection"> {{ link.nameEn }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    </template>
    <template v-else>
        <li>
            <router-link :to="link.url" :data-link="link.dataLink" :data-lang="link.dataLang" @click="currentPathNameClick">
                {{ link.name }}</router-link>
        </li>
    </template>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    props: {
        link: Object,
    },

    data() {
        return {
            menuCollections: [
                {
                    id: 'c1',
                    nameRu: 'СКВОЗЬ ВЕСНУ',
                    nameEn: 'THROUGH SPRING',
                    url: '/collection',
                },
                {
                    id: 'c2',
                    nameRu: 'БИОПОЛЯРНОСТЬ',
                    nameEn: 'BIOPOLARITY',
                    url: '/collection',
                },
                {
                    id: 'c3',
                    nameRu: 'ИГРА',
                    nameEn: 'GAME',
                    url: '/collection',
                },
                {
                    id: 'c4',
                    nameRu: 'ЭСКИЗ',
                    nameEn: 'SKETCH',
                    url: '/collection',
                },
                {
                    id: 'c5',
                    nameRu: 'ВЕНЕЦИЯ ВИСКОНТИ',
                    nameEn: 'VENICE VISCONTI',
                    url: '/collection',
                },
                {
                    id: 'c6',
                    nameRu: 'AFTERPATYA',
                    nameEn: 'AFTERPATYA',
                    url: '/collection',
                },
                {
                    id: 'c7',
                    nameRu: 'ДЯДЮШКИН СОН',
                    nameEn: "UNCLE'S DREAM",
                    url: '/collection',
                },
            ],
            currentLink: window.location.pathname.split('/').pop(),
            linkHref: '/',
        }
    },
    methods: {
        selectLinkMenu() {
            document.querySelectorAll("[data-link]")
                .forEach(link => {
                    this.linkHref = link.getAttribute('data-link');
                    if (this.currentLink === this.linkHref) {
                        link.style.backgroundColor = '#030303';
                        link.style.color = '#FFFFFF';
                    }
                })
        },

        ...mapMutations(['selectCollection', 'currentPathNameClick']),
    },
    mounted() {
        this.selectLinkMenu();
    },
    computed: {
        ...mapState(['currentCollection', 'currentLang']),
    },
}
</script>