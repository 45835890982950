<template>
        <CollectionComponent :key="componentKeyPage" :collection="selectCollection()"/> 
</template>

<script>
import { useHead } from '@unhead/vue';
import CollectionComponent from '@/components/CollectionComponent.vue';

import { mapState } from 'vuex';

export default {
    name: 'collection',
    components: { CollectionComponent },
    setup() {
        useHead ({
        title:
            'Коллекция САЛОН&СТУДИЯ SHALIONI CREATIVE',
        meta: [
            {
            name: 'collection',
            content: 'Коллекция САЛОН&СТУДИЯ SHALIONI CREATIVE',
            }
        ],
        });
    },
    data() {
        return {
            spring: [
                {id: 1, img: require('@/assets/img/collections/spring/1.jpg')},
                {id: 2, img: require('@/assets/img/collections/spring/2.jpg')},
                {id: 3, img: require('@/assets/img/collections/spring/3.jpg')},
                {id: 4, img: require('@/assets/img/collections/spring/4.jpg')},
                {id: 5, img: require('@/assets/img/collections/spring/5.jpg')},
                {id: 6, img: require('@/assets/img/collections/spring/6.jpg')},
                {id: 7, img: require('@/assets/img/collections/spring/7.jpg')},
                {id: 8, img: require('@/assets/img/collections/spring/8.jpg')},
            ],
            biopolarity: [
                {id: 1, img: require('@/assets/img/collections/biopolarity/1.jpg')},
                {id: 2, img: require('@/assets/img/collections/biopolarity/2.jpg')},
                {id: 3, img: require('@/assets/img/collections/biopolarity/3.jpg')},
                {id: 4, img: require('@/assets/img/collections/biopolarity/4.jpg')},
                {id: 5, img: require('@/assets/img/collections/biopolarity/5.jpg')},
                {id: 6, img: require('@/assets/img/collections/biopolarity/6.jpg')},
                {id: 7, img: require('@/assets/img/collections/biopolarity/7.jpg')},
                {id: 8, img: require('@/assets/img/collections/biopolarity/8.jpg')},
                {id: 9, img: require('@/assets/img/collections/biopolarity/9.jpg')},
                {id: 10, img: require('@/assets/img/collections/biopolarity/10.jpg')},
            ],
            game: [
                {id: 1, img: require('@/assets/img/collections/game/1.jpg')},
                {id: 2, img: require('@/assets/img/collections/game/2.jpg')},
                {id: 3, img: require('@/assets/img/collections/game/3.jpg')},
                {id: 4, img: require('@/assets/img/collections/game/4.jpg')},
                {id: 5, img: require('@/assets/img/collections/game/5.jpg')},
                {id: 6, img: require('@/assets/img/collections/game/6.jpg')},
                {id: 7, img: require('@/assets/img/collections/game/7.jpg')},
                {id: 8, img: require('@/assets/img/collections/game/8.jpg')},
                {id: 9, img: require('@/assets/img/collections/game/9.jpg')},
            ], 
            sketch: [
                {id: 1, img: require('@/assets/img/collections/sketch/1.jpg')},
                {id: 2, img: require('@/assets/img/collections/sketch/2.jpg')},
                {id: 3, img: require('@/assets/img/collections/sketch/3.jpg')},
                {id: 4, img: require('@/assets/img/collections/sketch/4.jpg')},
                {id: 5, img: require('@/assets/img/collections/sketch/5.jpg')},
                {id: 6, img: require('@/assets/img/collections/sketch/6.jpg')},
                {id: 7, img: require('@/assets/img/collections/sketch/7.jpg')},
                {id: 8, img: require('@/assets/img/collections/sketch/8.jpg')},
                {id: 9, img: require('@/assets/img/collections/sketch/9.jpg')},
            ],  
            venice: [
                {id: 1, img: require('@/assets/img/collections/venice/1.jpg')},
                {id: 2, img: require('@/assets/img/collections/venice/2.jpg')},
                {id: 3, img: require('@/assets/img/collections/venice/3.jpg')},
                {id: 4, img: require('@/assets/img/collections/venice/4.jpg')},
                {id: 5, img: require('@/assets/img/collections/venice/5.jpg')},
                {id: 6, img: require('@/assets/img/collections/venice/6.jpg')},
                {id: 7, img: require('@/assets/img/collections/venice/7.jpg')},
                {id: 8, img: require('@/assets/img/collections/venice/8.jpg')},            
            ], 
            afterpatya: [
                {id: 1, img: require('@/assets/img/collections/afterpatya/1.jpg')},
                {id: 2, img: require('@/assets/img/collections/afterpatya/2.jpg')},
                {id: 3, img: require('@/assets/img/collections/afterpatya/3.jpg')},
                {id: 4, img: require('@/assets/img/collections/afterpatya/4.jpg')},
                {id: 5, img: require('@/assets/img/collections/afterpatya/5.jpg')},
                {id: 6, img: require('@/assets/img/collections/afterpatya/6.jpg')},
                {id: 7, img: require('@/assets/img/collections/afterpatya/7.jpg')},           
            ], 
            dream: [
                {id: 1, img: require('@/assets/img/collections/dream/1.jpg')},
                {id: 2, img: require('@/assets/img/collections/dream/2.jpg')},
                {id: 3, img: require('@/assets/img/collections/dream/3.jpg')},
                {id: 4, img: require('@/assets/img/collections/dream/4.jpg')},
                {id: 5, img: require('@/assets/img/collections/dream/5.jpg')},
                {id: 6, img: require('@/assets/img/collections/dream/6.jpg')},
                {id: 7, img: require('@/assets/img/collections/dream/7.jpg')}, 
                {id: 6, img: require('@/assets/img/collections/dream/8.jpg')},
                {id: 7, img: require('@/assets/img/collections/dream/9.jpg')},           
            ],        
        }
    },
    methods: {
        selectCollection() {
            switch (this.currentCollectionId) {
                case 'c1':
                    return this.spring;
                case 'c2':
                    return this.biopolarity;
                case 'c3':
                    return this.game;
                case 'c4':
                    return this.sketch;
                case 'c5':
                    return this.venice;
                case 'c6':
                    return this.afterpatya;
                case 'c7':
                    return this.dream;
                default:
                    return this.spring;
            }
        },
    },
    computed: {
        ...mapState(['currentCollectionId', 'componentKeyPage']),       
    },
}
</script>