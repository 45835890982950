import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Collections from '@/views/Collections.vue'
import About from '@/views/About.vue'
import Buy from '@/views/Buy.vue'
import News from '@/views/News.vue'
import Showroom from '@/views/Showroom.vue'
import Contacts from '@/views/Contacts.vue'

import Collection from '../views/Collection.vue'

import NotFoundView from '../views/NotFoundView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index
  },
  {
    path: '/collections',
    name: 'collionects',
    component: Collections
  },
  {
    path: '/collection',
    name: 'collection',
    component: Collection
  },
  {
   path: '/about',
   name: 'about',
   component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

   // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  { path:'/buy/:pageNumber?',
    name: 'buy',
    component: Buy

  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/showroom',
    name: 'showroom',
    component: Showroom
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts
  },  
  {
    path: '/:pathMatch(.*)', 
    name: 'notFound',
    component: NotFoundView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

