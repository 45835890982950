import { createStore } from 'vuex';

export default createStore({
  state: {  
    myStorage: window.sessionStorage,
    currentLang: sessionStorage.getItem('language') || ['ru', 'en'].includes(navigator.language.slice(0, 2).toLowerCase())? navigator.language.slice(0, 2).toLowerCase() : 'ru',
    currentPathName: window.location.pathname.split('/').pop(),    
    currentText:{},
    currentCollectionId: 'c1',
    classOpacity: '',
    componentKeyPage: 0, 

    menuTexts: {
        "menu-1": {
            ru: "КОЛЛЕКЦИИ",
            en: "COLLECTIONS",
        },
        "menu-2": {
            ru: "О НАС",
            en: "ABOUT",
        },
        "menu-3": {
            ru: "КУПИТЬ",
            en: "BUY",
        },
        "menu-4": {
            ru: "НОВОСТИ",
            en: "NEWS",
        },
        "menu-5": {
            ru: "ШОУРУМ",
            en: "SHOWROOM",
        },
        "menu-6": {
            ru: "КОНТАКТЫ",
            en: "CONTACTS",
        },
        "menu-footer-1": {
          ru: "КОЛЛЕКЦИИ",
          en: "COLLECTIONS",
        },
        "menu-footer-2": {
            ru: "О НАС",
            en: "ABOUT",
        },
        "menu-footer-3": {
            ru: "КУПИТЬ",
            en: "BUY",
        },
        "menu-footer-4": {
            ru: "НОВОСТИ",
            en: "NEWS",
        },
        "menu-footer-5": {
            ru: "ШОУРУМ",
            en: "SHOWROOM",
        },
        "menu-footer-6": {
            ru: "КОНТАКТЫ",
            en: "CONTACTS",
        },
    },
    footerTexts: {
      "footer-1": {
          ru: "Контакты",
          en: "Contacts",
      },
      "footer-2": {
          ru: "Адрес",
          en: "Adress",
      },
      "footer-3": {
          ru: "Санкт-Петербург, Декабристов ул., 15",
          en: "St. Petersburg, Dekabristov str., 15",
      },
      "footer-4": {
          ru: "E-mail",
          en: "E-mail",
      },
      "footer-5": {
          ru: "shalioni@mail.ru",
          en: "shalioni@mail.ru",
      },
    },
    collectionsTexts: {
        "collections-title": {
            ru: "коллекции",
            en: "collections",
        },
        "collections-1": {
            ru: "СКВОЗЬ ВЕСНУ",
            en: "THROUGH SPRING",
        },
        "collections-2": {       
            ru: 'БИОПОЛЯРНОСТЬ',
            en: "BIOPOLARITY",
        },
        "collections-3": {
            ru: "ИГРА",
            en: "'GAME",
        },
        "collections-4": {       
            ru: 'ЭСКИЗ',
            en: "SKETCH",
        },
        "collections-5": {       
            ru: 'ВЕНЕЦИЯ ВИСКОНТИ',
            en: "VENICE VISCONTI",
        },
        "collections-6": {
            ru: "AFTERPATYA",
            en: "AFTERPATYA",
        },
        "collections-7": {       
            ru: 'ДЯДЮШКИН СОН',
            en: "UNCLE'S DREAM",
        }    
    },
    collectionTexts: {
        'c1': {
            "collection-1": {
                ru: "СКВОЗЬ ВЕСНУ",
                en: "THROUGH SPRING",
            },
            "collection-2": {       
                ru: 'коллекция haute couture',
                en: "collection haute couture",
            }
        },
        'c2': {
            "collection-1": {
                ru: "БИОПОЛЯРНОСТЬ",
                en: "BIOPOLARITY ",
            },
            "collection-2": {       
                ru: "коллекция homme de luxe",
                en: "collection homme de luxe",
            }
        },
        'c3': {
            "collection-1": {
                ru: "ИГРА",
                en: "GAME",
            },
            "collection-2": {       
                ru: "коллекция homme de luxe",
                en: "collection homme de luxe",
            }
        },
        'c4': {
            "collection-1": {
                ru: "ЭСКИЗ",
                en: "SKETCH",
            },
            "collection-2": {       
                ru: "коллекция homme de luxe",
                en: "collection homme de luxe",
            }
        },
        'c5': {
            "collection-1": {
                ru: "ВЕНЕЦИЯ ВИСКОНТИ",
                en: "VENICE VISCONTI",
            },
            "collection-2": {       
                ru: "коллекция homme de luxe",
                en: "collection homme de luxe",
            }
        },
        'c6': {
            "collection-1": {
                ru: "AFTERPATYA",
                en: "AFTERPATYA",
            },
            "collection-2": {       
                ru: 'коллекция pret-a-porter',
                en: "collection pret-a-porter",
            }
        },
        'c7': {
            "collection-1": {
                ru: "ДЯДЮШКИН СОН",
                en: "UNCLE'S DREAM",
            },
            "collection-2": {       
                ru: 'коллекция haute couture',
                en: "collection haute couture",
            }
        },
    },

    aboutTexts: {     
      "about-1": {
          ru: "O SHALIONI CREATIVE",
          en: "ABOUT SHALIONI CREATIVE",
      },
      "about-2": {       
          ru: function () {         
              const divEl = document.querySelector('[data-lang="about-2"]');
              divEl.textContent = '';
              const pEl = document.createElement('p');
              pEl.textContent = ' – петербургский дизайнер, создающий одежду класса люкс, отличающуюся сложным кроем по классическим канонам и обилием ручной работы. Особое внимание Алексей уделяет внутренней стороне моделей, считая, что она должна быть не менее красива, чем внешняя.';
              const spanEl = document.createElement('span');
              spanEl.textContent = 'Алексей Шаленый';
              spanEl.className = "content-about__info_text-bold";
              pEl.prepend(spanEl);
              divEl.appendChild(pEl);
  
              const p2El = document.createElement('p');
              p2El.textContent = ' существует уже более двадцати лет и обслуживает самых взыскательных частных клиентов. В 2001 году Алексей дебютировал с коллекцией «Сквозь весну», представив затем более 10 коллекций в Петербурге, Калининграде и Берлине, Турции. Параллельно дизайнер разрабатывал сценические костюмы для группы «Колибри», а также сезонные коллекции для компаний-производителей одежды. Алексей Шаленый является неоднократным обладателем гран-при и первых мест на различных профессиональных конкурсах («Модулор», «Русский силуэт» и др.)';
              const span2El = document.createElement('span');
              span2El.textContent = 'Shalioni Creative';
              span2El.className = "content-about__info_text-italic";
              p2El.prepend(span2El);
              const text = document.createTextNode('Его бренд ');
              p2El.prepend(text);
              divEl.appendChild(p2El);
          },
          en: "Alexey Shaleny is a St. Petersburg designer who creates luxury clothing, distinguished by a complex cut according to classical canons and an abundance of handmade work. Alexey pays special attention to the inside of the models, believing that it should be no less beautiful than the outside. Shalioni Creative has been in existence for over twenty years and serves the most demanding private clients. In 2001, Alexey debuted with the “Through Spring” collection, then presenting more than 10 collections in St. Petersburg, Kaliningrad and Berlin, Turkey. At the same time, the designer developed stage costumes for the Hummingbird group, as well as seasonal collections for clothing manufacturing companies. Alexey Shaleny is a multiple winner of the Grand Prix and first places at various professional competitions (“Modulor”, “Russian Silhouette”, etc.)",
      },
  
      "about-3": {
          ru: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit deleniti ratione quo laudantium iure labore, culpa, atque odit magnam cumque sunt dolorum! Sit aliquid rerum placeat deleniti iure nesciunt iste!Eum, aliquid modi. Quasi tenetur expedita eum aliquid vero modi esse tempore cumque facere aspernatur autem harum cum aperiam corporis totam quos reiciendis culpa, illum et assumenda sequi quod. Quibusdam?Totam accusamus earum quidem eius iste repudiandae tempora, doloremque dolorum harum ullam. Eos eveniet ea esse cumque ullam vel commodi corrupti maiores excepturi. Dolores, eligendi asperiores! Aliquam cum eum molestias! Facilis dignissimos rerum vitae consequuntur in consequatur magni optio, deleniti libero, omnis minus nisi soluta, alias facere repudiandae aliquid dolor a? Voluptatibus animi unde eligendi. Dicta ea numquam similique? Asperiores!",
          en: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit deleniti ratione quo laudantium iure labore, culpa, atque odit magnam cumque sunt dolorum! Sit aliquid rerum placeat deleniti iure nesciunt iste!Eum, aliquid modi. Quasi tenetur expedita eum aliquid vero modi esse tempore cumque facere aspernatur autem harum cum aperiam corporis totam quos reiciendis culpa, illum et assumenda sequi quod. Quibusdam?Totam accusamus earum quidem eius iste repudiandae tempora, doloremque dolorum harum ullam. Eos eveniet ea esse cumque ullam vel commodi corrupti maiores excepturi. Dolores, eligendi asperiores! Aliquam cum eum molestias! Facilis dignissimos rerum vitae consequuntur in consequatur magni optio, deleniti libero, omnis minus nisi soluta, alias facere repudiandae aliquid dolor a? Voluptatibus animi unde eligendi. Dicta ea numquam similique? Asperiores!",
      },
    },
    orderTexts: {
        "order-1": {
            ru: "СВЯЗАТЬСЯ С НАМИ",
            en: "CONNECT WITH US",
        },
        "order-2": {
            ru: "Напишите нам. Мы с вами свяжемся в ближайшее время и назначим время для посещения.",
            en: "Write to us. We will contact you shortly and schedule a time for your visit.",
        },
        "order-3": {
            ru: "Если вы желаете заказать изделие или купить, напишите нам. Рады ответить на интересующие вас вопросы. В салоне имеется ассортимент тканей и фурнитуры. Осуществляем пошив также из ваших материалов.",
            en: "If you would like to order a product or buy, write to us. We are happy to answer your questions. The salon has an assortment of fabrics and accessories. We also carry out tailoring from your materials.",
        },
        "order-4": {
            ru: "Ваше имя",
            en: "Your name",
        },
        "order-5": {
            ru: "Мужчина",
            en: "Man",
        },
        "order-6": {
            ru: "Женщина",
            en: "Woman",
        },
        "order-7": {
            ru: "Ваш электронный адрес / Телефон",
            en: "Your email address / Phone number",
        },
        "order-8": {
            ru: "ОТПРАВИТЬ",
            en: "SEND",
        },
        "order-9": {
            ru: "Сообщение отправлено",
            en: "Message sent",
        }
    },    
    newsTexts: {
      "news-1": {
          ru: "23 года SHALIONI CREATIVE",
          en: "23 yars SHALIONI CREATIVE",
      },
      "news-2": {       
          ru: '18 февраля 2024 г. SHALIONI CREATIVE отметил 23-летие создание бренда',
          en: "February 18, 2024 SHALIONI CREATIVE celebrated the 23rd anniversary of the creation of the brand",
      }    
    },
    showroomTexts: {
      "showroom-1": {
          ru: "SHOWROOM",
          en: "SHOWROOM",
      },
      "showroom-2": {
          ru: "Адрес",
          en: "Address",
      },
      "showroom-3": {
          ru: "Санкт-Петербург, Декабристов ул., 15",
          en: "St. Petersburg, Dekabristov str., 15",
      },
      "showroom-4": {
          ru: "E-mail",
          en: "E-mail",
      },
      "showroom-5": {
          ru: "shalioni@mail.ru",
          en: "shalioni@mail.ru",
      },
    },
    contactsTexts: {
        "contacts-1": {
            ru: "КОНТАКТЫ",
            en: "CONTACTS",
        },
        "contacts-2": {
            ru: "Адрес",
            en: "Address",
        },
        "contacts-3": {
            ru: "Санкт-Петербург, Декабристов ул., 15",
            en: "St. Petersburg, Dekabristov str., 15",
        },
        "contacts-4": {
            ru: "САЛОН & СТУДИЯ работает по предварительной записи",
            en: "SALON & STUDIO is open by appointment",
        },
        "contacts-5": {
            ru: "E-mail",
            en: "E-mail",
        },
        "contacts-6": {
            ru: "shalioni@mail.ru",
            en: "shalioni@mail.ru",
        },
    },  
  },
  getters: {
  },
  mutations: {
    componentKeyPageIncr(state) {
        state.componentKeyPage += 1;
    },
    addClassOpacity(state) {
        state.classOpacity = 'content-collection__opacity';
    },
    dellClassOpacity(state) {
        state.classOpacity = '';
    },
    selectLangRU(state) {      
      state.currentLang = 'ru';
    },
    selectLangEN(state) {
      state.currentLang = 'en';
    },
    currentLangMenu(state) {
      for (const key in state.menuTexts) {
          const elem = document.querySelector(`[data-lang=${key}]`);
          if (elem) {
              elem.textContent = state.menuTexts[key][state.currentLang];
          }      
      }   
    },
    currentLangFooter(state) {
      for (const key in state.footerTexts) {
          const elem = document.querySelector(`[data-lang=${key}]`);
          if (elem) {
              elem.textContent = state.footerTexts[key][state.currentLang];
          }      
      }   
    },
    checkPagePatName(state) {
      switch (state.currentPathName) {
        case 'Index':
          state.currentText = state.homeTexts;
            break;
        case 'Collections':
          state.currentText = state.collectionsTexts;            
            break;  
        case 'Collection':
          state.currentText = state.collectionTexts;            
            break; 
        case 'About':
          state.currentText = state.aboutTexts;            
            break; 
        case 'Buy':
          state.currentText = state.orderTexts;            
            break; 
        case 'News':
          state.currentText = state.newsTexts;            
            break; 
        case 'Showroom':
          state.currentText = state.showroomTexts;            
            break; 
        case 'Contacts':
          state.currentText = state.contactsTexts;            
            break;       
        default:
          state.currentText = state.homeTexts;
            break;
      }
    },        
    changeLang(state) {
        for (const key in state.currentText) {
            const elem = document.querySelector(`[data-lang=${key}]`);
            if (elem) {
                if (typeof state.currentText[key][state.currentLang] === 'function') { 
                  state.currentText[key][state.currentLang](); 
                        
                } else {
                    elem.textContent = state.currentText[key][state.currentLang];
                }
            }      
        }
    },
    currentPathNameClick(state, e) {      
        state.currentPathName = e.target.dataset.link; 
    },
    selectCollection(state, e) {      
        state.currentCollectionId = e.target.id; 
        state.currentPathName = e.target.href.split('/').pop();   
    },
    currentLangCollection(state) {
        for(const name in state.collectionTexts) {
            if (name === state.currentCollectionId) {
                for (const key in state.collectionTexts[name]) {
                    const elem = document.querySelector(`[data-lang=${key}]`);
                    if (elem) {
                        elem.textContent = state.collectionTexts[name][key][state.currentLang];
                    }      
                } 
            } 
        }
    }  
  },
  actions: {      
  },
  modules: {
  }
})
