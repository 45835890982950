<template>
  <div class="box-content">
    <HeaderComponent />
    <div class="content center">
      <div class="content__banner"></div>
      <div class="content__logo"></div>
      <div class="content__slider-content">
        <SliderComponent />
      </div>
    </div>
    <FooterComponent />
    <div class="modalDev" id="modalDev">
      <p>Сайт в разработке</p>
    </div>
  </div>
</template>

<script>
import { useHead } from '@unhead/vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import SliderComponent from '@/components/SliderComponent.vue';

export default {
  name: 'index',
  components: { HeaderComponent, FooterComponent, SliderComponent },
  setup() {
    useHead({
      title:
        'САЛОН&СТУДИЯ "SHALIONI CREATIVE"',
      meta: [
        {
          name: 'index',
          content: 'САЛОН&СТУДИЯ "SHALIONI CREATIVE" производитель мужской и женской одежды',
        }
      ],
    });
  },
  methods: {
    modalDev() {
      this.modalDev = document.getElementById('modalDev');
      setTimeout(() => {
        this.modalDev.style.visibility = 'hidden';
      }, 5000);
    }
  },
  mounted() {
    this.modalDev();
  }
};

</script>

<style scoped lang="scss">
#modalDev {
  width: 100%;
  height: 100px;
  background-color: #b0b0b0;
  border: 1px solid #b0b0b0;
  color: red;
  font-family: Gilroy-Bold;
  font-size: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 10px;
  visibility: visible;
}
</style>

