<template>
    <div class="box-content">
        <HeaderComponent />
        <div class="content-showroom center">
            <div class="content-showroom__info">
                <h1 class="content-showroom__info_title" data-lang="showroom-1"></h1>
                <h3 data-lang="showroom-2"></h3>
                <h3 data-lang="showroom-3"></h3>
                <br>
                <h3 data-lang="showroom-4"></h3>
                <h3 data-lang="showroom-5"></h3>
            </div>
            <div class="content-showroom__img-box">
                <img class="content-showroom__img" src="@/assets/img/showroom.jpg" alt="img">
            </div>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import { useHead } from '@unhead/vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

import { mapMutations } from 'vuex';

export default {
    name: 'showroom',
    components: { HeaderComponent, FooterComponent },
    setup() {
        useHead({
            title:
                'Шоурум САЛОН&СТУДИЯ SHALIONI CREATIVE',
            meta: [
                {
                    name: 'showroom',
                    content: 'Шоурум САЛОН&СТУДИЯ SHALIONI CREATIVE',
                }
            ],
        });
    },
    methods: {
        ...mapMutations(['checkPagePatName', 'changeLang']),
    },
    mounted() {
        this.checkPagePatName();
        this.changeLang();
    },
}
</script>