<template>
    <div class="box-content">
        <div class="box-clicks" @click="sliderClick">
            <HeaderComponent />
            <div class="content-collection center" :class="classOpacity">
                <h2 class="content-collection__title" data-lang="collection-1"></h2>
                <p class="content-collection__text" data-lang="collection-2"></p>
                <div class="content-collection__img-content">
                    <CollectionItemComponent v-for="item in collection" :key="item.id" :item="item" :id="item.id" />
                </div>
            </div>
            <div class="modalForSlider center" id="modalCol">
                <SliderCollectionComponent :key="componentKeySlider" :collection="this.currentCol" />
            </div>
            <FooterComponent />
        </div>
    </div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import FooterComponent from './FooterComponent.vue';
import CollectionItemComponent from './CollectionItemComponent.vue';
import SliderCollectionComponent from '@/components/SliderCollectionComponent.vue';

import { mapState, mapMutations } from 'vuex';

export default {
    name: 'CollectionComponent',
    components: { HeaderComponent, FooterComponent, CollectionItemComponent, SliderCollectionComponent },
    props: {
        collection: Array,
    },
    data() {
        return {
            modalCol: '',
            currentCol: [],
            idImg: 1,
            componentKeySlider: 0,
        }
    },
    methods: {
        ...mapMutations(['checkPagePatName', 'currentLangCollection', 'addClassOpacity', 'dellClassOpacity', 'componentKeyPageIncr']),

        forceRenderSlider() {
            this.componentKeySlider += 1;
        },
        sliderClick(e) {
            const links = e.target.closest('a');
            this.modalCol = document.getElementById("modalCol");
            if (!links) {
                const isModal = e.composedPath().includes(this.modalCol);
                if (e.target.tagName === 'IMG' && !isModal) {
                    this.idImg = e.target.id;
                    const start = this.collection.slice(this.idImg - 1, this.collection.length + 1);
                    const end = this.collection.slice(0, this.idImg - 1);
                    this.currentCol = start.concat(end);
                    this.modalCol.style.display = "block";
                    this.modalCol.scrollIntoView({ block: "start", behavior: "smooth" });
                    this.addClassOpacity();
                    this.forceRenderSlider();
                } else {
                    if (!isModal && this.modalCol.style.display === "block") {
                        this.modalCol.style.display = "none";
                        this.dellClassOpacity();
                    }
                }
            }
            else {
                const isMenuCollections = e.composedPath().includes(e.target.closest('.menu-collections__content'));
                if (isMenuCollections) {
                    this.modalCol.style.display = "none";
                    this.dellClassOpacity();
                    this.componentKeyPageIncr();
                }
                else {
                    this.dellClassOpacity();
                }
            }
        }
    },
    mounted() {
        this.checkPagePatName();
        this.currentLangCollection();
    },
    computed: {
        ...mapState(['classOpacity'])
    },
}
</script>