<template>
    <div class="box-content">
        <HeaderComponent />
        <div class="content-about center">
            <div class="content-about__info">
                <h2 class="content-about__info_title" data-lang="about-1"></h2>
                <div class="content-about__info_text" data-lang="about-2"></div>
                <br>
                <p class="content-about__info_text" data-lang="about-3"></p>
            </div>
            <div class="content-about__img-box"></div>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import { useHead } from '@unhead/vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

import { mapMutations } from 'vuex';

export default {
    name: 'about',
    components: { HeaderComponent, FooterComponent },
    setup() {
        useHead({
            title:
                'История САЛОН&СТУДИЯ SHALIONI CREATIVE',
            meta: [
                {
                    name: 'about',
                    content: 'О нас - история создания САЛОН&СТУДИЯ SHALIONI CREATIV',
                }
            ],
        });
    },
    methods: {
        ...mapMutations(['checkPagePatName', 'changeLang']),
    },
    mounted() {
        this.checkPagePatName();
        this.changeLang();
    },
}
</script>