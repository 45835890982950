<template>
    <div class="box-content">
        <HeaderComponent />
        <div class="content-contacts center">
            <div class="content-contacts__info">
                <h1 class="content-contacts__info_title" data-lang="contacts-1"></h1>
                <h3 data-lang="contacts-2"></h3>
                <h3 data-lang="contacts-3"></h3>
                <br>
                <p data-lang="contacts-4"></p>
                <br>
                <h3 data-lang="contacts-5"></h3>
                <h3 data-lang="contacts-6"></h3>
            </div>
            <div class="content-contacts__map-box">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1999.242557755778!2d30.299791179096704!3d59.92811722276999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469630e29e6eace5%3A0xe3e86c1992b96a14!2z0J_RgNCw0YfQtdGH0L3Ri9C5INC_0LXRgC4sIDEyLCDQodCw0L3QutGCLdCf0LXRgtC10YDQsdGD0YDQsywgMTkwMDAw!5e0!3m2!1sru!2sru!4v1707305745510!5m2!1sru!2sru"
                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import { useHead } from '@unhead/vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

import { mapMutations } from 'vuex';

export default {
    name: 'contacts',
    components: { HeaderComponent, FooterComponent },
    setup() {
        useHead({
            title:
                'Контакты САЛОН&СТУДИЯ SHALIONI CREATIVE',
            meta: [
                {
                    name: 'contacts',
                    content: 'Контакты САЛОН&СТУДИЯ SHALIONI CREATIVE',
                }
            ],
        });
    },
    methods: {
        ...mapMutations(['checkPagePatName', 'changeLang']),
    },
    mounted() {
        this.checkPagePatName();
        this.changeLang();
    },
}
</script>