<template>
    <div class="box-content center">
        <div class="content-notFound">
            <h1>404</h1>
            <h2>Страница не найдена. Попробуйте начать
                <a class="content-notFound__link" href="/">сначала</a>
            </h2>
        </div> 
    </div>
</template>

<script>
export default {
    name: 'notFoundView',
}
</script>

