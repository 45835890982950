<template>
    <header class="header">
        <div class="header__top-content center">
            <div class="header__logo-box">
                <a href="/">
                    <img class="img-logo" src="@/assets/img/Logo.png" alt="img_logo">
                </a>
            </div>
            <div class="header__langs">
                <button @click="buttonClickRu" class="header__langs_btn" data-btn="ru">RU</button>
                <button @click="buttonClickEn" class="header__langs_btn" data-btn="en">EN</button>
            </div>
        </div>
        <div class="header__botoom-content center">
            <nav class="menu">
                <ul class="menu__list">
                    <MenuComponent v-for="link in menu" :key="link.id" :link="link"/>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
import MenuComponent from './MenuComponent.vue';
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'HeaderComponent',
    components: { MenuComponent },
    
    data() {
        return {
            menu: [
                {
                    id: 1,
                    name: 'КОЛЛЕКЦИИ',
                    url: '/collections',
                    dataLink: 'Collections',
                    dataLang: "menu-1",
                    children: true,
                    menuClass: "menu-collections",
                },
                {
                    id: 2,
                    name: 'О НАС',
                    url: '/about',
                    dataLink: 'About',
                    dataLang: "menu-2",
                    children: false,
                },
                {
                    id: 3,
                    name: 'КУПИТЬ',
                    url: '/buy',
                    dataLink: 'Buy',
                    dataLang: "menu-3",
                    children: false,
                },
                {
                    id: 4,
                    name: 'НОВОСТИ',
                    url: '/news',
                    dataLink: 'News',
                    dataLang: "menu-4",
                    children: false,
                },
                {
                    id: 5,
                    name: 'ШОУРУМ',
                    url: '/showroom',
                    dataLink: 'Showroom',
                    dataLang: "menu-5",
                    children: false,
                },
                {
                    id: 6,
                    name: 'КОНТАКТЫ',
                    url: '/contacts',
                    dataLink: 'Contacts',
                    dataLang: "menu-6",
                    children: false,
                },
            ],
        }
    },
    methods: {

        ...mapMutations(['selectLangRU', 'selectLangEN', 'currentLangMenu', 'currentLangFooter', 'currentLangCollection', 'checkPagePatName', 'changeLang']),

        buttonClickRu() {
            const langButtons = document.querySelectorAll('[data-btn]');
            this.resetActiveClass(langButtons, "menu__btn_active");
            this.selectLangRU();
            sessionStorage.setItem('language', 'ru');
            this.updateLang();
        },
        buttonClickEn() {
            const langButtons = document.querySelectorAll('[data-btn]');
            this.resetActiveClass(langButtons, "menu__btn_active");
            this.selectLangEN();
            sessionStorage.setItem('language', 'en');
            this.updateLang();
        },
        resetActiveClass(arr, activeClass) {
            arr.forEach(elem => {
                elem.classList.remove(activeClass);
            });
        },
        checkActiveLangButton() {
            switch (this.currentLang) {
                case "ru":
                    document
                        .querySelector('[data-btn="ru"]')
                        .classList.add("menu__btn_active");
                    break;
                case "en":
                    document
                        .querySelector('[data-btn="en"]')
                        .classList.add("menu__btn_active");
                    break;
                default:
                    document
                        .querySelector('[data-btn="ru"]')
                        .classList.add("menu__btn_active");
                    break;
            }
        },
        updateLang() {
            this.checkActiveLangButton();
            this.currentLangMenu();
            this.currentLangFooter();
            this.checkPagePatName();
            this.changeLang();
            this.currentLangCollection();
        }
    },

    computed: {
        ...mapState(['currentLang']),
    },
    mounted() {
        this.currentLangMenu();
        this.checkActiveLangButton();
    },
}
</script>
