<template>
    <div class="product-item">
        <div class="product-item__img-box">
            <img class="product-item__img" :src="item.img" alt="img-product-item">
        </div>
        <details v-if="this.currentLang === 'ru'" class="product-item__text-content">
            <summary class="product-item__heading">{{ item.titleRu }}
                <div class="product-item__description">
                    <h4>Подробное описание</h4>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="15" height="15" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512"
                        xml:space="preserve" class="">
                        <g>
                            <path
                                d="M496 0H16A16 16 0 0 0 0 16v480a16 16 0 0 0 16 16h480a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16zm-16 480H32V32h448z"
                                fill="#000000" opacity="1" data-original="#000000" class=""></path>
                        </g>
                    </svg>
                </div>
            </summary>
            <div class="product-item__detailed-box">
                <p class="product-item__detailed-box_text">{{ item.descriptionRu }}</p>
                <hr>
                <h5 class="product-item__detailed-box_title">Состав</h5>
                <p class="product-item__detailed-box_text">{{ item.structureRu }}</p>
                <hr>
                <h5 class="product-item__detailed-box_title">Размер</h5>
                <p class="product-item__detailed-box_text">{{ item.sizeRu }}</p>
                <hr>
                <h5 class="product-item__detailed-box_title">Цена</h5>
                <p class="product-item__detailed-box_text">{{ item.priceRu }}</p>
            </div>
        </details>
        <details v-if="this.currentLang === 'en'" class="product-item__text-content">
            <summary class="product-item__heading">{{ item.titleEn }}
                <div class="product-item__description">
                    <h4>Description</h4>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="15" height="15" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512"
                        xml:space="preserve" class="">
                        <g>
                            <path
                                d="M496 0H16A16 16 0 0 0 0 16v480a16 16 0 0 0 16 16h480a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16zm-16 480H32V32h448z"
                                fill="#000000" opacity="1" data-original="#000000" class=""></path>
                        </g>
                    </svg>
                </div>
            </summary>
            <div class="product-item__detailed-box">
                <p class="product-item__detailed-box_text">{{ item.descriptionEn }}</p>
                <hr>
                <h5 class="product-item__detailed-box_title">Structure</h5>
                <p class="product-item__detailed-box_text">{{ item.structureEn }}</p>
                <hr>
                <h5 class="product-item__detailed-box_title">Size</h5>
                <p class="product-item__detailed-box_text">{{ item.sizeEn }}</p>
                <hr>
                <h5 class="product-item__detailed-box_title">Price</h5>
                <p class="product-item__detailed-box_text">{{ item.priceRu }}</p>
            </div>
        </details>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'ProductComponent',
    props: {
        item: Object,
    },
    computed: {
        ...mapState(['currentLang']),
    },
}
</script>